@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap);
:root {
  
  /* Main Settings  */
  --main-background-color: #021627;

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(68, 68, 68);

  /* Nav Settings  */
  --nav-link-color: rgb(255, 255, 255);
  --nav-link-hover-color: #eead0e;
  --nav-link-hover-background-color: none;
  --nav-background-color: #021627;
  --nav-height: 100px;
  --nav-logo-width: 70px;
  --nav-logo-font-size: 16px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: #021627;
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(255, 255, 255);
  --mobile-nav-link-hover-color: #eead0e;
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgba(216, 216, 216, 0);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px #eead0e;

  /* Minting Area */
  --minting-box-color: #181313;
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg,#604ae5 0%,#813eee 100%);
  --minting-button-text-color: white;

}







.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
#link1, #roadmap, #utilities, #faq, #about{
  scroll-margin-top: 100px;
}
body {
  color: var(--main-text-color);
  position: relative;
  background: var(--main-background-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin: auto;
}

.nav-logo {
  width: var(--nav-logo-width);
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;
  font-weight: 600;
  padding: 20px;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}


.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 12345;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: rgb(117, 69, 69);
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 123456;
  width: 100%;
  transition: left 0.3s ease;
  top: 0;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
  font-weight: 600;
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: var(--nav-logo-height);
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 32px;
  height: 32px;
  padding: 0 10px 0 10px;
}

.social-icons {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
  }



  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.home {
  max-width: 50%;
}

header {
  max-width: 1600px;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: flex;
  padding: 35px;
  justify-content: space-between;
}

.mintHeader {
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

@media only screen and (min-width: 800px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    grid-gap: 20px;
    gap: 20px;
  }
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 1600px;
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq {
  max-width: 1600px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
  border: 1px solid #eead0e;
}

.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin: auto;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
}



.loading:after {
  content: " .";
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.card {
  position: relative;
}

#link1 h3 {
  color: rgba(155, 155, 155, 0.041);
  font-size: 100px;
  position: absolute;
  top: -30px;
  left: 0;
}

#link1 h1 {
  color: #021627;
  text-shadow:
    -1px -1px 0 #eead0e,
    1px -1px 0 #eead0e,
    -1px 1px 0 #eead0e,
    1px 1px 0 #eead0e;
  font-size: 60px;
}

#link1 p {
  color: rgb(211, 211, 211);
}

#link1 .MuiPaper-elevation1 {
  box-shadow: var(--card-shadow);
}

@font-face {
  font-family: royal;
  src: url(/static/media/Rumblebrave-Yw72.759b07ce.otf);
}

.aboutinfo h1 {
  color: #eead0e;
  font-family: royal;
  font-size: 80px;
  font-weight: 100;
  text-align: center;
}

.aboutinfo p {
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  max-width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  font-size: 24px;
}

.characters {
  display: flex;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
}

.character img {
  width: 300px;
  margin-left: auto;
  display: block;
  margin-right: auto;
  border-radius: 20px;
}

.character h1 {
  color: #eead0e;
  font-family: 'Ms Madi', cursive;
  font-size: 36px;
  text-align: center;
  margin-top: 5px;
}

.character p {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-size: 22px;
  margin-top: 40px;
  text-align: justify;
}

.character {
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.144);
  border-radius: 10px;
  max-width: 400px;
  margin-top: 25px;
}
#utilities{
  margin-top: 100px;
}
.toputil h1 {
  color: #eead0e;
  font-family: royal;
  font-size: 80px;
  font-weight: 100;
  text-align: center;
}
.toputil p{
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  max-width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  font-size: 24px;
  margin-bottom: 50px;
}

.utility {
  margin-top: 50px;
  display: flex;
  color: white;
  font-family: 'Raleway', sans-serif;
  grid-gap: 50px;
  gap: 50px;
  max-width: 50vw;
  margin-left: auto;
  margin-right: auto;
}
.utility h1{
  font-size: 26px;
}
.utility p{
  font-size: 22px;
}
.utility img {
  width: 130px;
}
#utilities hr{
  margin-top: 50px;
  height: 1px;
  max-width: 50vw;
  background-color: rgba(255, 255, 255, 0.144);
  margin-left: auto;
  margin-right: auto;
  border: 0;
}
#roadmap{
  margin-top: 100px;
}
#roadmap h1{
  color: #eead0e;
  font-family: royal;
  font-size: 80px;
  font-weight: 100;
  text-align: center;
}

#roadmap p{
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  max-width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  font-size: 24px;
  margin-bottom: 50px;
}
#faq h1{
  color: #eead0e;
  font-family: royal;
  font-size: 80px;
  font-weight: 100;
  text-align: center;
}
#faq .MuiPaper-root{
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.144);
  padding: 5px;
  margin-bottom: 15px;
  max-width: 60vw;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
#faq .MuiTypography-root{
  font-size: 24px!important;
}
#faq .MuiSvgIcon-root{
  fill: #eead0e;
}
footer{
  height: 50px;
  margin-top: 50px;
  align-items: center;
  text-align: center;
  color: #eead0e;
}
nav{
  position: fixed;
  top: 0;
  z-index: 5000;
}
.topbanner img{
  width: 100vw;
  overflow-x: hidden;
  margin-top: 70px;
}
body{
  overflow-x: hidden;
}

#link1{
  margin-top: 80px;
}
.custom-mint-container img{
  max-width: 350px;
  border-radius: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media(max-width: 600px)
{
  header{
    display: block;
  }
  .custom-mint-container img{
    width: 150px;
  }
  .characters{
    display: block;
  }
  .mobile-nav-logo{
    width: 80px;
  }
  .utility{
    display: block;
  }
  .utility img{
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }
  .topbanner{
    margin-top: 50px;
  }
  .home{
    max-width: 100%;
    margin-bottom: 50px;
  }
  #link1 h3 {
    color: rgba(155, 155, 155, 0.041);
    font-size: 50px;
    position: absolute;
    top: -12px;
    left: 0;
}
#faq .MuiPaper-root {
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.144);
  padding: 5px;
  margin-bottom: 15px;
  max-width: 90vw;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
}
